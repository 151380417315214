@import "../main/abstracts/index";
body[data-template="home"]{
    .section--article-list{
        position: relative;
        .wappen{
            position: absolute;
            right: 50px;
            top: 0px;
            transform: translateY(-25%);
            img{
                @include media-breakpoint-down(xl) {
                    max-width: 220px;
                }
            }
            @include media-breakpoint-down(lg) {
            right : 10px;
                img{
                    max-width: 150px;
                }
                
            }

        }
        .article-list__item{
            a{
                background-color: $color__white;
                border-radius: 8px;
                color: $color__font;
                text-decoration: none;
                font-weight: $font__weight--normal;
                display: block;
                height: 100%;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                @include hover-focus-visible-within{
                    .more-info{
                        background-color: $color__primary;
                        img{
                            @include recolor($color__white)
                        }
                    }
                }
                &:before{
                    display: none;
                }
                .card-header{
                    padding: 60px 60px 0px 60px;
                    @include media-breakpoint-down(md) {
                        padding: 30px 30px 0 30px;
                    }
                    .card-date{
                        .section{
                            color: $color__primary;
                            padding-bottom: 14px;
                            display: block;
                        }
                        h3{
                            font-weight: $font__weight--normal;
                            line-height: 1.2;
                            @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 20px, 25px);
                        }
                    }
                }
                .card-body{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding: 12px 60px 60px 60px;
                    font-size: 18px;
                    @include media-breakpoint-down(md) {
                        padding: 12px 30px 30px 30px;
                    }
                    .card-text__info{
                        @include truncate-text(3);
                        margin-bottom: 55px;
                        flex: 1;
                        @include media-breakpoint-down(md) {
                            margin-bottom: 25px;
                        }
                    }
                   
                }
                .more-info{
                    width: 100%;
                    display: flex;
                    justify-content: center; 
                    align-items: center;
                    padding: 20px 0;
                    border-top: 1px solid $color__gray;
                    img{
                        height: 17px;
                        width: auto;
                    }
                }
            }
        }
        .article-list__footer{
            .splide__link{
                justify-content: flex-start;
                @media(max-width: 575px){
                    margin-top: 0;
                    margin-bottom: 25px;
                }
            }
            .splide__arrows{
                justify-content: flex-end;
                @include media-breakpoint-down(md) {
                    margin-top: 16px;
                    gap: 2px;  

                }
                .splide__arrow{
                    border-radius: 50%;
                    width: 58px;
                    height: 58px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        padding: 2px 0;
                    }
                    &[disabled]{
                        background-color: $color__white;
                        img{
                            @include recolor($color__primary)
                        }
                    }
                }
            }
        }
    }

}
